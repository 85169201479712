
.topbar .changeLanguage {
    float: right;
    border-left:1px dotted #e5e1d9;
    padding: 10px 0 8px 0px;
    margin: 0 0 0 10px;
    position: relative;
    width:50px;
    font-size: 16px;
    text-align: right;
    text-transform: uppercase;
    display: none;
}

.topbar .chartid {
    float: right;
    border-left:1px dotted #e5e1d9;
    padding: 6px 2px 8px 0px;
    margin: 0 0 0 10px;
    position: relative;
    width:40px;
    font-size: 16px;
    text-align: right;
    text-transform: uppercase;
    margin-left: 10px;
}

.topbar .chartid i {
    font-size: 26px;
}

.topbar .chartid a { 
    color:#1f1d1d;
}

.topbar .chartid a.selected, .topbar .chartid a:hover { 
    color:#ff455f;
}

.topbar .pills {
    float: right;
    border-left:1px dotted #e5e1d9;
    padding: 6px 2px 8px 10px;
    margin: 0 0 0 10px;
    position: relative;
    width:40px;
    font-size: 16px;
    text-align: right;
    text-transform: uppercase;
    margin-left: 10px;
}

.topbar .pills i {
    font-size: 26px;
}

.topbar .pills a { 
    color:#1f1d1d;
}

.topbar .pills a.selected, .topbar .pills a:hover { 
    color:#ff455f;
}

.topbar .unread a.selected, .topbar .unread a:hover { 
    color:#ff455f;
}

.topbar .changeLanguage i {
    margin-left: 4px;
    color:#2ac2d3;
}

.topbar .tungumal {
    list-style-type: none;
    position: absolute;
    right:0;
    top:25px;
    display: none;
}

.topbar .tungumal.opna {
    display: inline-block;
}

.topbar .tungumal li { display: inline-block; }

.topbar .tungumal li button {
    background-color: #fff;
    border: 1px solid #2ac2d3;
    margin-left: 10px;
    height:30px;
    width:100px;
    text-align: right;
    border-radius: 2px;
    font-size: 15px;
    font-weight: 700;
    padding-right: 18px;
}

.topbar .tungumal li.tung_is button { border-bottom: none;}

.topbar .logingout {
    float: right;
    border-left:1px dotted #e5e1d9;
    padding: 10px 10px 8px 0px;
    margin: 0 0 0 10px;
    position: relative;
    width:45px;
    font-size: 16px;
    text-align: right;
    text-transform: uppercase;
}

.topbar .logingout button {
    padding: 0;
    background-color: transparent;
    border: none;
    cursor: pointer;
}

.topbar .logingout button i {
    font-size: 20px;
}

.topbar .unread {
    float: right;
    padding: 10px 0 8px 0px;
    margin: 0 0 0 10px;
    position: relative;
    width:30px;
    font-size: 12px;
    text-align: right;
    text-transform: uppercase;
}

.topbar .unread a {
    color:#1f1d1d;
    text-decoration: none;
}

.topbar .unread i {
    font-size: 21px;
}

.topbar .unread span {
    position:absolute;
    top:3px;
    right:-5px;
    background:#a30404;
    height:16px;
    width:16px;
    border-radius: 11px;
    color:#fff;
    text-align: center;
    line-height: 16px;
    font-size: 12px;
}

.topbar .unread span[data-count="0"] {
    background:#ccc;
    display: none;
}

.topbar .skraskast_top {
    float: right;
    border-left:1px dotted #e5e1d9;
    padding: 4px 10px 4px 10px;
    margin: 0 0 0 10px;
    position: relative;
    width:auto;
    font-size: 15px;
    text-align: right;
    display: none;
}

.topbar .skraskast_top a {
    padding: 0 15px 0 10px;
    font-weight: 500;
    text-decoration: none;
    color:#0a565f;
    background-color: #6df0f1;
    line-height: 32px;
    height: 30px;
    display: inline-block;
    border-radius: 5px;
    transition: 0.3 ease-in-out;
}

.topbar .skraskast_top a:hover {
    color:#fff;
    background-color: #0a565f;
}

.topbar .skraskast_top a i {
    font-size: 19px;
    float: left;
    margin-right: 5px;
    display: inline-block;
    margin-top: 5px;
}

/*
.topbar.is .tungumal li.tung_is button {
    background-color: #2ac2d3;
    border: 1px solid #1da3b2;
    color:#fff;
}

.topbar.en .tungumal li.tung_en button {
    background-color: #2ac2d3;
    border: 1px solid #1da3b2;
    color:#fff;
}
*/
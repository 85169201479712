
@media only screen and (max-width: 1440px) {
    h3.heildarskor {
        font-size: 16px;
    }

    .lifsgaedi .lifsgaedi_heildarskor_mynd i {
        font-size: 40px;
        margin-top: 5px;
    }

    .lifsgaedi .lifsgaedi_heildarskor {
        width: 24%;
    }

    .lifsgaedi .lifsgaedi_likamlegvirkni, .lifsgaedi .lifsgaedi_andleglidan, .lifsgaedi .lifsgaedi_almennlidan {
        width: 19%;
        height: 44px;
    }

    .lifsgaedi .lifsgaedi_verkir {
        width: 15%;
        height: 44px;
    }

    .heild {
        padding-left: 5px;
        padding-top: 1px;
    }

    .heild strong {
        font-size: 17px;
    }
}

@media only screen and (max-width: 1240px) {
    .lifsgaedi .lifsgaedi_heildarskor_mynd {
        width: 5%;
    }

    .lifsgaedi .lifsgaedi_heildarskor {
        width: 23%;
    }

    .lifsgaedi .lifsgaedi_heildarskor_mynd i {
        font-size: 38px;
        margin-top: 6px;
      }
}

@media only screen and (max-width: 1080px) {
    .skrakast_btn {
        width: calc(50% - 0px);
    }

    .samskipti_btn, .timapantanir_btn, .lokakostum_btn {
        width: calc(50% - 20px);
    }

    .samskipti_btn {
        margin-left: 0;
        width: calc(50% - 0px);
    }

    .alpha {
        width: 100%;
    }

    .gamma {
        width: 100%;
        margin: 10px 0;
    }

    .lifsgaedi .lifsgaedi_heildarskor_mynd {
        width: 8%;
        margin-bottom: 10px;
    }

    .lifsgaedi .lifsgaedi_heildarskor {
        width: 90%;
        margin-bottom: 10px;
    }

    .lifsgaedi .lifsgaedi_verkir {
        width: 25%;
        height: 44px;
        padding-left: 3px;
    }

    .lifsgaedi .lifsgaedi_likamlegvirkni, .lifsgaedi .lifsgaedi_andleglidan, .lifsgaedi .lifsgaedi_almennlidan {
        width: 25%;
        height: 44px;
    }
}

@media only screen and (max-width: 980px) { 

    .sjuklingur_upplysingar {
        width:100%;
        padding-right: 0;
    }

    .sjuklingur_aukaupplysingar {
        width: 100%;
        padding-left: 0;
    }

    .sjuklingur_aukaupplysingar.pl20 {
        padding-left: 0 !important;
    }

    .adgengi {
        width: 25%;
        display: inline-block;
        margin-bottom: 8px;
    }

    .ermedleyfi {
        float: none;
        margin: 10px 0;
    }

    #menu {
        display: none;
        position: fixed;
        top:0;
        left:0;
        width:100%;
        height:100vh;
        background:rgba(255,255,255,0.94);
        z-index: 10;
    }

    #menu.open {
        display: block;
    }

    #menu ul.menu { 
        width: calc(100% - 20px);
        margin: 100px 10px 0 10px;
    }

    #menu ul.menu li {
        width:100%;
        float: left;
        margin: 15px 0;
        text-align: center;
        font-size: 28px;
    }

    .home ul.menu li a {
        color:#000;
    }

    #menu ul.menu li.divider {
        border-right: 0px solid #e9e9e9;
        padding-right: 0px;
        margin-right: 0px;
        margin-bottom: 40px;
    }
    
    #menu ul.menu li.btn, #menu ul.menu li.btn_inn {
        margin: 15px 0 0;
    }

    #menu ul.menu li.btn_inn a {
        display: inline-block;
        padding: 14px 28px;
        margin-top: 22px;
        color:#fff;
        font-size: 24px;
    }

    #menu ul.menu li.btn a {
        display: inline-block;
        padding: 14px 28px;
        margin-top: 0px;
        background:#fff;
        font-size: 20px !important;
    }
    #menu ul.menu li.btn button {
        background:#fff;
        font-size: 20px !important;
        padding: 10px 28px;
        border:1px solid #eee;
        font-weight: 600;
    }
    

    .greinar {
        width:100%;
    }

    .greinar article {
        padding-right: 0;
    }
    .greinar article.grein h1 {
        font-size: 36px;
        margin: 0px 0 5px;
    }

    .greinar article.grein .sidebar {
        display:none;
    }

    .greinar article.grein .main-layout {
        width: calc(100% - 0px);
        padding-left: 0px;
    }

    .hlidarstika {
        width: 100%;
    }

    .greinar article:nth-child(2), .greinar article {
        height: auto;
        width: 100%;
        padding-right: 0px;
        margin-bottom: 30px;
    }

    .greinar figure, .greinar article:nth-child(2) figure {
        height: 200px;
        width: 100%;
    }

    .admin-system {
        padding-top: 50px;
    }

}

@media only screen and (max-width: 780px) { 

    .heildarskor_mynd i {
        font-size: 30px;
    }

    main {
        padding: 0 0 100px;
    }

    .yfirlit {
        padding-bottom: 60px;
    }

    .hideonmobile {
        display: none;
    }

    .ahersla {
        width: 100% !important;
        padding: 0 20px !important;
        margin-bottom: 30px;
    }

    .sixtycent, .fortycent {
        width: calc(100% - 0px);
        margin: 10px 0px 10px 0;
    }

    .ahersla:last-child {
        padding-right: 20px !important;
    }

    .mobile-header {
        display: block;
        text-align: center;
    }

    .skra_mainwin {
        width:100%;
    }

    .skra_upplysingar {
        width:100%;
    }

    section .background_mynd {
        display: none;
    }

    .alpha {
        width:100%;
        margin: 0px;
    }

    .gamma {
        width:100%;
        margin: 20px 0px;
    }

    .delta {
        width:100%;
        margin: 0px;
    }

    .fyrirsogn {
        width: 100%;
        max-width:100% !important;
        font-size:30px !important;
    }

    .undirfyrirsogn {
        max-width: 100% !important;
        font-size: 18px;
    }

    .takkar {
        width: calc(100% - 0px) !important;
    }

    .timalina {
        width: calc(100% - 0px) !important;
    }

    .ibodi img {max-width: 160px;}

    .stjornufrettir {
        width: calc(100% - 13px);
        margin-left: 13px;
    }

    .forsidufrettir .frettalisti {
        width: calc(100% - 0px);
        padding: 10px;
    }

    .auglysing_kubbur {
        width: 100%;
        margin: 20px 0 40px;
    }

    .toplisti ol {
        padding: 0;
        width: 100%;
        margin: 0 ;
    }

    .ljosipunkturinn {
        height: 310px;
        width: calc(100% - 15px);
        margin-left: 15px;
    }

    .greinar article:nth-child(2), .greinar article {
        height: auto;
        width: 100%;
        padding-right: 0px;
        margin-bottom: 30px;
    }

    .greinar figure, .greinar article:nth-child(2) figure {
        height: 200px;
        width: 100%;
    }

    .adrargreinar article, .adrargreinar article:nth-child(2) {
        width:100%;
        height: auto;
    }

    .adrargreinar figure, .adrargreinar article:nth-child(2) figure {
        width:100%;
        height:160px;
        margin-right: 20px;
    }

    .sidebar {
        display: none;
    }

    div.admin-system {
        width: calc(100% - 0px);
        margin: 0px;
        padding: 0 10px;
    }

    .userprofile .username {
        display: none;
    }

    .logingout {
        display: none;
    }

    .innskraningssida {
        padding: 0 20px;
    }

    .loginmethod li {
        font-size: 15px;
    }

    .loginmethod li i {
        display: block;
        font-size: 22px;
        margin-bottom: 5px;
    }

    .topbar h2 {
        display: none !important;
    }

    .skrakast_btn {
        width: calc(50% - 10px);
        min-height: 140px;
        margin: 10px 10px 10px 0px;
    }

    .lokakostum_btn {
        width: calc(50% - 10px);
        margin: 10px 0 10px 10px;
        min-height: 140px;
    }

    .samskipti_btn {
        width: calc(50% - 10px);
        margin: 10px 10px 10px 0px;
        min-height: 140px;
    }

    .timapantanir_btn {
        width: calc(50% - 10px);
        margin: 10px 0 10px 10px;
        min-height: 140px;
    }

    .lifsgaedi .lifsgaedi_heildarskor_mynd {
        width: 12%;
        margin-bottom: 10px;
    }

    .lifsgaedi .lifsgaedi_heildarskor {
        width:88%;
        margin-bottom: 10px;
    }

    .lifsgaedi .lifsgaedi_verkir {
        width:50%;
        margin-bottom: 20px;
        padding-left: 0;
    }

    .lifsgaedi .lifsgaedi_likamlegvirkni {
        width:50%;
        margin-bottom: 20px;
    }

    .lifsgaedi .lifsgaedi_andleglidan {
        width:50%;
        margin-bottom: 20px;
    }

    .lifsgaedi .lifsgaedi_almennlidan {
        width:50%;
        margin-bottom: 20px;
    }

    .heild {
        font-size: 14px;
    }

    #hamborgari, .mobile-footer {
        display: block  !important;
    }

    .mobileaddval.open {
        display: block  !important;
    }

    .topbar .unread, .topbar .chartid, .topbar .pills {
        display: none;
    }

    .changePass {
        width: 100%;
        margin-right: 0px;
    }

    .breytalykilordi {
        width: 100%;
        padding: 10px 0;
        border: 0px solid rgba(0, 0, 0, 0.1);
    }

    .fiftycent {
        width: calc(100% - 0px);
        margin: 0 !important;
    }

    .lifsgaeda_flokkur {
        width: 100%;
        padding: 0 !important;
    }

    ul.menu li a {
        font-size: 28px !important;
    }

    .svorvidspurnigum {
        width: 100%;
        display: inline-block;
        vertical-align: top;
        padding: 0px 0 20px;
    }

    .nyttprof {
        width: 100%;
        display: inline-block;
        margin: 10px 0 0;
        text-align: left;
    }

    .vistaprof {
        width:100%;
    }

}

@media only screen and (max-width: 440px) {  
    .fastirlidir article {
        display: inline-block;
        width: 100%;
        text-align: center;
        margin-bottom: 40px;
    }

    .forsidufrettir .frettalisti {
        width: 100%;
        margin: 0;
    }

    .toplisti ol, .toplisti ol:last-child {
        width: 100%;
        margin: 0;
    }

    ul.menu {
        padding: 0;
        margin: 20px 10px 0 0;
    }

    #headermenu {
        z-index: 100;
        position: absolute;
        right: 0;
    }
}
.sidebar {
    position: fixed;
    top:0;
    left:0;
    height: 100vh;
    width: 240px;
    background: rgba(255,255,255,0);
    background: linear-gradient(90deg, rgba(247,237,248,1) 0%, rgba(255,255,255,0) 79%);
}

.sidebar h1 {
    width: 100%;
    height:44px;
    padding: 0 22px;
    line-height: 40px;
    margin: 10px 0 0;
    font-size: 22px;
    text-indent: 30px;
    position: relative;
    font-weight: 600;
    border-bottom: 1px dotted #efdef2;
}

.sidebar h1 a {
    color:#221f20;
    text-decoration: none;
}

.sidebar h1::before {
    content: "";
    height:34px;
    width:34px;
    background-image: url(../../img/fruma_merki.png);
    position: absolute;
    top:3px;
    left: 15px;
    z-index: 1;
    background-size: cover;
}
.sidebar ul {
    list-style-type: none;
    padding: 0;
    margin: 0 20px 20px;
    width: calc(100% - 40px);
    float: left;
    font-weight: 400;
}

.sidebar ul li {
    width:100%;
    margin:0 0 5px 0;
}


.sidebar ul li a {
    color:#67635d;
    font-size: 16px;
    display: block;
    border-radius: 5px;
    padding: 6px 10px;
    border:1px solid transparent;
    text-decoration: none;
    transition: 0.4s ease-in-out;
}

.sidebar ul li button {
    color:#67635d;
    font-size: 16px;
    display: block;
    border-radius: 5px;
    padding: 6px 10px;
    border:1px solid transparent;
    text-decoration: none;
    transition: 0.4s ease-in-out;
    width: 100%;
    background-color: transparent;
    text-align: left;
    cursor: pointer;
}

.sidebar ul li.inactive a {
    opacity: 0.5;
}

.sidebar ul li a:hover {
    border:1px solid #ff455f;
    color:#383531;
}

.sidebar ul li button:hover {
    border:1px solid #ff455f;
    color:#383531;
}

.sidebar ul li a.selected {
    background-color: #ff455f;
    color:#fff;
    border:1px solid #ff455f;
}

.sidebar ul li a i {
    margin-right: 6px;
}

.sidebar ul li a.selected i {
    color:#fff;
}

.sidebar h4 {
    margin:20px 30px 5px;
    font-size: 14px;
    color:#9f9e9e;
    font-weight:500;
}

.topbar {
    width: 100%;
    height:45px;
    display: block;
    padding: 0;
    margin: 10px 0 20px;
    border-bottom:1px dotted #efdef2;
}

.topbar h2 {
    height:36px;
    line-height:36px;
    margin:0;
    padding: 0;
    display: inline-block;
    font-size: 22px;
    font-weight: 500;
}

.userprofile {
    display: inline-block;
    height:40px;
    width: 40px;
    float: right;
    border-left: 1px dotted #e5e1d9;
    padding: 4px 0 4px 15px;
    margin-left: 10px;
    position: relative;
}

.userprofile a {
    text-decoration: none;
    color:#1f1d1d;
    display: block;
    width:100%;
    height:100%;
    position: absolute;
    top:0;
    left:0;
    padding: 6px 10px;
}

.userprofile a i {
    font-size: 26px;
}

.userprofile a:hover, .userprofile a.selected {
    color:#ff455f;
}

.avatar {
    overflow:hidden;
    margin-top: 4px;
    display: inline-block;
    vertical-align: top;
}

.username {
    display: inline-block;
    vertical-align: top;
    line-height: 40px;
    height: 40px;
    margin-right: 10px;
    font-size: 16px;
}

.username strong {
    color:#2ac2d3;
}

.tungumal {
    margin:10px 0;
}

ul.litir {
    width:calc(100% - 60px);
    font-size: 14px;
    margin:5px 30px;
    list-style-type: none;
    padding: 0;
    display: none;
}

ul.litir li {
    width: 100%;
    margin: 8px 0;
}

ul.litir span {
    height: 18px;
    display: inline-block;
    width:18px;
    background-color: #ff455f;
    border-radius: 3px;
    float: left;
    margin-right: 15px;
}

ul.litir span.gulur {
    background-color: #f8d483;
}

ul.litir span.ljosblar {
    background-color:#2ac2d3;
}

ul.litir span.blar {
    background-color:#3b72a1;
}

ul.litir span.svartur {
    background-color:#221f20;
}

ul.litir span.ljosgraenn {
    background-color:#1effbc;
}

ul.litir span.fjolublar {
    background-color:#6149CD;
}

.usersidebar {
    position: fixed;
    top:0;
    right:0;
    height: 100vh;
    width: 250px;
    background: #fdfcfb;
    border-left: 1px dotted #e5e1d9;
    padding: 10px 20px;
}

.usersidebar h2 {
    height: 36px;
    line-height: 36px;
    margin: 0;
    padding: 0;
    display: inline-block;
    font-size: 18px;
    font-weight: 500;
}

.usersidebar h3 {
    font-size: 16px;
    border-bottom: 1px solid rgba(0,0,0,0.1);
    padding-bottom: 3px;
    margin: 10px 0;
}

.usersidebar h3 a {
    float: right;
}

.usersidebar h3.heildarskor {
    border-bottom: none;
    margin:0;
}

.heildarskor {
    margin:10px 0 30px;
}

.UserImage {
    width:150px;
    height:150px;
    margin: 0 auto;
    border-radius: 75px;
    background-color: #fff;
    border: 1px solid #e5e1d9;
}

.notandaupplysingar {
    text-align: center;
    margin:20px 0;
    font-size: 16px;
}

.notandaupplysingar a {
    color: #383531;
    text-decoration: none;
}

.notandalinkar {
    text-align: center;
    margin-bottom: 20px;
}

.notandalinkar a {
    display: inline-block;
    background-color: #fff;
    border: 1px solid #e5e1d9;
    color: #383531;
    text-decoration: none;
    padding: 4px 8px;
    font-weight: 600;
    font-size: 16px;
}